import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Button, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { createAdministratorHistoryColumns } from './administratorHistoryColumn';
import { actions as administratorHistoryActions } from '../../redux/modules/administratorHistory';
import styles from './styles';
import { ModalPage } from 'nav/ModalPage';

const AdministratorHistoryPage: ModalPage<{}> = (props) => {
  const dispatch = useDispatch();
  const { adminHistory, loading, error } = useSelector((state: RootState) => state.administratorHistory);
  const { onClickCancel } = props;

  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    dispatch(administratorHistoryActions.get());
  }, [dispatch]);

  const columns = createAdministratorHistoryColumns();
  const closePage = () => {
    dispatch(administratorHistoryActions.setError({ error: undefined }));
    onClickCancel();
  };

  return (
    <Container component="main">
      <Box sx={styles.modal}>
        <Typography variant="h4" sx={styles.headerLabel}>
          User Activity
        </Typography>
        {loading ? (
          <Typography>Loading...</Typography>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <DataGrid
            rows={adminHistory}
            columns={columns}
            autoHeight
            pagination
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10]}
            getRowId={(row) => row.id}
          />
        )}
        <Box sx={styles.buttonContainer}>
          <Button onClick={closePage} variant="contained">
            Close
          </Button>
          {loading && (
            <Box sx={styles.progress}>
              <CircularProgress size={10} />
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default AdministratorHistoryPage;
