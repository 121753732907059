import {all} from 'redux-saga/effects'
import {saga as environments} from './modules/environments'
import {saga as devices} from './modules/devices'
import {saga as exportData} from './modules/exportData'
import {saga as app} from './modules/app'
import {saga as usage} from './modules/usage'
import {saga as activity} from './modules/activity'
import {saga as users} from './modules/users'
import {saga as pain} from './modules/pain'
import {saga as auth} from './modules/auth'
import {saga as search} from './modules/search'
import {saga as patients} from './modules/patients'
import {saga as administratorHistory } from './modules/administratorHistory'

const saga = function*() {
  yield all([
    app(),
    pain(),
    environments(),
    devices(),
    usage(),
    activity(),
    users(),
    auth(),
    patients(),
    exportData(),
    search(),
    administratorHistory(),
  ])
}
export default saga
