import { createAction, createReducer } from '@reduxjs/toolkit';
import { takeEvery, getContext, put, call } from 'redux-saga/effects';
import { Dictionary } from 'lodash';
import Device from '../../models/Device';
import Api from 'Api';
import Patient from 'models/Patient';
import User from 'models/User';

export type AdministratorHistory = {
  id: string;
  patient: Dictionary<Patient>
  device: Dictionary<Device>
  user: Dictionary<User>
  actionType: string;
  date: string;
};

export type AdministratorHistoryState = {
  devices: Dictionary<Device>;
  loading: boolean;
  error: string | undefined;
  adminHistory: AdministratorHistory[];
};

export const initialState: AdministratorHistoryState = {
  devices: {},
  loading: false,
  error: undefined,
  adminHistory: [],
};

export const actions = {
  set: createAction<{ administratorHistory: AdministratorHistory[] }>('administratorHistory/set'),
  get: createAction('administratorHistory/get'),
  setLoading: createAction<{ loading: boolean }>('administratorHistory/setLoading'),
  setError: createAction<{ error?: string }>('administratorHistory/setError'),
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.set, (state, action) => {
      state.adminHistory = action.payload.administratorHistory;
      state.loading = false;
      state.error = undefined;
    })
    .addCase(actions.setLoading, (state, action) => {
      state.loading = action.payload.loading;
    })
    .addCase(actions.setError, (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
    });
});

const getSaga = function* () {
  yield put(actions.setLoading({ loading: true }));
  const api: Api = yield getContext('api');
  const administratorHistory: AdministratorHistory[] = yield call(api.getAdministratorHistory);
  yield put(actions.set({ administratorHistory }));
  
};

export const saga = function* () {
  yield takeEvery(actions.get, getSaga);
};
