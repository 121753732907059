import { GridColDef } from '@mui/x-data-grid';

export const createAdministratorHistoryColumns = (): GridColDef[] => {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 2,
    },
    {
      field: 'userEmail',
      headerName: 'User Email',
      valueGetter: params => `${params.row.user?.email}`,
      flex: 2,
    },
    {
      field: 'actionType',
      headerName: 'Action Type',
      valueGetter: params => {
        const actionMap = {
          1: 'Create Patient',
          2: 'Assign Serial Number',
          3: 'Remove Serial Number',
        };
        return actionMap[params.row.actionType]
      },
      flex: 2,
    },
    {
      field: 'PatientName',
      headerName: 'Patient',
      valueGetter: params => `${params.row.patient?.firstName} ${params.row.patient?.lastName}`,
      flex: 2,
    },
    {
      field: 'deviceName',
      headerName: 'Device UDID',
      valueGetter: params => params.row.device?.udid || '--',
      flex: 2,
      align: 'center', 
      headerAlign: 'center',
    },
    {
      field: 'date',
      headerName: 'Action Date',
      valueGetter: params => {
        const date = new Date(params.row.date);
        return date.toISOString().split('T')[0];
      },
      flex: 2,
    }
  ];
  return columns;
};
