export type Environment = {
  name: string
  url: string
}
const environments: Environment[] = [
  {name: 'Development', url: 'http://localhost:3000'},
  {name: 'Staging', url: 'https://staging.api.actastim.theragen.com'},
  {name: 'Production', url: 'https://production.api.actastim.theragen.com'},
]

export default environments
