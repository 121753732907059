import Device, { DeviceData } from './Device'
import JsonModel from './JsonModel'
import Patient, {PatientData} from './Patient'
import User, { UserData } from './User'

export type AdministratorHistoryData = {
  id?: string | undefined
  patient: PatientData
  device?: DeviceData 
  user?: UserData
  actionType?: number
  date?: Date
}

class AdministratorHistory extends JsonModel {
  id?: string
  patient?: Patient
  device?: Device
  user?: User
  actionType?: number
  date?: Date
  

  constructor(args: AdministratorHistoryData) {
    super('AdministratorHistoryData', args)
    this.id = args.id
    this.actionType = args.actionType
    this.date = args.date

    if (args.patient) {
      this.patient = new Patient(args.patient)
    }
    if (args.device) {
      this.device = new Device(args.device)
    }
    if (args.user) {
      this.user = new User(args.user)
    }
  }

  asJson() {
    const {patient, device, user} = this
    return {patient, device, user}
  }
}

export default AdministratorHistory
